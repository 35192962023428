/*  校区管理接口   */

import request from '@/utils/request'


// 获取校区列表
export const getCampuslist = (page = 1) => {
    return request({
        method: 'get',
        url: '/setup/campus' + '?page=' + page,

    })
}

// 新增校区
export const addCampus = (data) => {
    return request({
        method: 'post',
        url: '/setup/campus-add',
        data
    })
}

// 编辑校区
export const editCampus = (data) => {
    return request({
        method: 'post',
        url: '/setup/campus-edit',
        data
    })
}
// 获取校区详情
export const getCampusdetails = (params) => {
    return request({
        method: 'get',
        url: '/setup/campus-details',
        params
    })
}
